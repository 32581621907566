 
.header-container {
  min-width: 100%;
  height: 240px;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px solid black;
  margin: 0;
  overflow: hidden;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 15px 0 rgba(0, 0, 0, 0.19);

  &:hover {

    & .background-image {
      transform: scale(1.1);
      transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
    }

    & .header-body-container {
      opacity: 0.9;
    }
  }

  .background-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  .header-body-container {
    height: 9rem;
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0px solid black;
    background-color: white;
    opacity: 0.7;
    position: absolute;
    border-radius: 5px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 15px 0 rgba(0, 0, 0, 0.19);


    p {
      font-size: 16px;
      color: #4a4a4a;
    }
  }
}

.frontPageCarousel {
  margin-top: 20px;
}

.carousel-image {
  height: 50vh;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  object-fit: cover;
}

.carousel-caption-container {
  width: 100%;
  padding: 1rem;
  border: 0px solid black;
  background-color: black;
  opacity: 0.7;
  border-radius: 5px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 15px 0 rgba(0, 0, 0, 0.19);

  p {
    font-size: 16px;
    color: white;
  }

  h3 {
    font-family: 'Homemade Apple', cursive;
  }
}

.react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-pdf__Page {
  max-width: calc(100% - 2em);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  margin: 1em;
}

.react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}
